import axios from 'axios'

const API = 'entregas-mapas'

const Entregas = {
    listar_pedidos(params){
        return axios(`${API}/entregas-lecheros`,{params: params})
    },
    listar_entrega_clientes(params){
        return axios(`${API}/entregas-clientes`,{params: params})
    }

}

export default Entregas
